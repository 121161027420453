
import './App.css';
import React from 'react';
import { Homepage } from './pages/homepage/homepage.mjs';

function App() {

  return (
    <>
      <Homepage />
    </>
  );
}

export default App;