/*eslint-disable */

import React, { useEffect, useState } from "react";

import "./homepage.css";

export const Homepage = () => {
  const [blogElements, setBlogElements] = useState([]);
  const [bookElements, setBookElements] = useState([]);

  const [loading, setLoading] = useState(false);

  const MEDIUM_FEED = "/blogs.json";
  const BOOKS = "/books.json";


  useEffect(() => {
    setLoading(true);

    fetch(MEDIUM_FEED)
      .then((res) => {
        setBlogElements([]);
        setLoading(true);
        return res.json();
      })
      .then((items) => {
        const blogItems = []
        items.forEach((item) => {
          const newBlogItems = {
            title: item.title,
            link: item.link,
            pubDate: item.pubDate,
            pubTime: item.pubTime,
            seoDescription: item.seoDescription,
            img: item.img,
            imgCredits: item.imgCredits,
          };
          blogItems.push(newBlogItems);
          setLoading(false);
        });
        setBlogElements(blogItems)
      });

    fetch(BOOKS)
      .then((res) => {
        setBookElements([]);
        setLoading(true);
        return res.json();
      })
      .then((items) => {
        const bookItems = []
        items.forEach((item) => {
          const newBookItem = {
            title: item.title,
            link: item.link,
            seoDescription: item.seoDescription,
            img: item.img,
          };
          bookItems.push(newBookItem);
          setLoading(false);
        });
        setBookElements(bookItems)
      });

  }, []);

  const renderBlogItem = (loading, items) => {
    if (loading) {
      return <p>Loading blogs</p>;
    }

    return items.map((item, index) => {
      return (
        <div key={index} className="blog--element">
          <img className="blog--element--img" src={item.img} />
          <div className="blog--element--content">
            <a target="_blank" href={item.link}>
              {item.title}
            </a>
            <p className="blog--element--content--description">{item.seoDescription}</p>
            <small>
              <a href={item.imgCredits} target="_blank">image credits</a>
            </small>
          </div>
        </div>
      );
    });
  };

  const renderBookItem = (loading, items) => {
    if (loading) {
      return <p>Loading books</p>;
    }

    return items.map((item, index) => {
      return (
        <div key={index} className="blog--element">
          <img className="blog--element--img" src={item.img} />
          <div className="blog--element--content">
            <a target="_blank" href={item.link}>
              {item.title}
            </a>
            <p className="blog--element--content--description">{item.seoDescription}</p>
          </div>
        </div>
      );
    });
  };

  return (
    <div className="">
      <div className="navigation--wrapper">
        <a className="navigation--logo" href="/">
          <img className="navigation--img" src="/BD.svg" />
        </a>
        <ul className="navigation navigation--container">
          <li className="navigation--element">
            <a href="#about">About</a>
          </li>
          <li className="navigation--element">
            <a href="#blog">Blogs</a>
          </li>
          <li className="navigation--element">
            <a href="#productfolio">Case studies</a>
          </li>
        </ul>
      </div>

      <section id="about">
        <div className="about--wrapper">
          <div className="about--personal">
            <h1 className="about--personal--title">Benjamin Dendas</h1>
            <p className="about--personal--description">
              Hello, I’m Benjamin a software developer turned product enthusiast
              based in Belgium. I'm passionate about software development and
              analysis, with a track record of successfully delivering multiple
              projects. Explore my portfolio to discover the diverse range of
              projects I've undertaken, showcasing my skills and dedication to
              the world of software. Feel free to send me a message to see if I
              can help you in any way.
            </p>

            <a href="/CV.pdf" target="_blank" className="blog--button">
              Download CV
            </a>

            <h2 className="socials--title">Follow me</h2>
            <div className="socials--wrapper">
              <a
                className="socials--element"
                href="https://www.linkedin.com/in/benjamindendas/"
              >
                <img src="/linkedin.svg" alt="linkedin" />
              </a>
              <a
                className="socials--element"
                href="https://github.com/BenjaminDendas"
              >
                <img src="/github.svg" alt="github" />
              </a>
              <a
                className="socials--element"
                href="https://medium.com/@benjamindendas"
              >
                <img src="/Medium.svg" alt="Medium" />
              </a>
              <a
                className="socials--element"
                href="https://twitter.com/BenjaminDendas"
              >
                <img src="/Twitter.svg" alt="Twitter" />
              </a>
            </div>
          </div>

          <img className="about--image" src="/me.jpg" />
        </div>
      </section>

      <section className="blog--wrapper" id="blog">
        <h2>Blogs</h2>

        <div className="blog--grid">
          {renderBlogItem(loading, blogElements)}
        </div>
      </section>

      <section className="concept--wrapper" id="productfolio">
        <h1>Case studies</h1>

        <h2>MealMentor</h2>
        <div className="concept--content">
          <p className="concept--description">MealMentor is an app that helps users live a healthier lifestyle by assisting them in eating healthy.
            By providing the necessary tools to track food consumption and on-demand advice from our advanced AI solutions or dedicated nutrition professional we help you become a healthier version of yourself!</p>

          <iframe className="fimga--frame" src="https://embed.figma.com/proto/TiJoCKqB9ug9R88ORIZrZ1/MealMentor?page-id=509%3A2645&node-id=509-2838&starting-point-node-id=509%3A2838&embed-host=share" allowfullscreen></iframe>
          <br />
          <a href="https://www.behance.net/gallery/201143591/MealMentor" target="_blank">Read the full case study</a>
        </div>
        <hr className="divider"/>
        <h2>UXChange</h2>
        <p className="book--info">SyntraPXL Group project</p>
        <div className="concept--content">
          <p className="concept--description">UXChange helps people with finding like-minded people that are in need for help with specific trade skills</p>
          <img className="concept--img" src="/uxchange.png" />
          <br />
          <a href="https://www.behance.net/gallery/194061849/UXChange" target="_blank">Read the full case study</a>
        </div>
      </section>

      <section className="blog--wrapper" id="books">
        <h2>Book suggestions <p className="book--info">not affiliated</p></h2>

        <div className="blog--grid">
          {renderBookItem(loading, bookElements)}
        </div>
      </section>
    </div>
  );
};
